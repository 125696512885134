import React from "react"; // Add this line
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "./store/appContext";
import Homepage from "./pages/homepage/homepage";
import WebLoader from "./components/webLoader/WebLoader";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/ScrollToTop";
import Unternehmen from "./pages/unternehmen/unternehmen";
import Vorteile from "./pages/vorteile/vorteile";
import Edelmetalle from "./pages/edelmetalle/edelmetalle";
import Wertsteigerung from "./pages/wertsteigerung/wertsteigerung";
import WirtschaftlicherNutzen from "./pages/wirtschaftlicher-nutzen/wirtschaftlicher_nutzen";
import Service from "./pages/service/service";
import SicherheitLagerung from "./pages/sicherheit-lagerung/sicherheit_lagerung";
import Besserstellung from "./pages/besserstellung/besserstellung";
import CostAverageEffect from "./pages/cost-average-effect/cost_average_effect";
import StrategicEternalValue from "./pages/strategic-eternal-value/strategic_eternal_value";
import Programmliste from "./pages/programmliste/programmliste";
import Vorsorge from "./pages/vorsorge/vorsorge";
import Kindersparplan from "./pages/kindersparplan/kindersparplan";
import FAQ from "./pages/faq/faq";
import Contact from "./components/contact/contact";
import CancelAppointment from "./components/cancelAppointment/cancelAppointment";

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop />
        <WebLoader />
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/unternehmen" element={<Unternehmen />} />
          <Route path="/vorteile" element={<Vorteile />} />
          <Route path="/edelmetalle" element={<Edelmetalle />} />
          <Route path="/wertsteigerung" element={<Wertsteigerung />} />
          <Route
            path="/wirtschaftlicher-nutzen"
            element={<WirtschaftlicherNutzen />}
          />
          <Route path="/service" element={<Service />} />
          <Route path="/sicherheit-lagerung" element={<SicherheitLagerung />} />
          <Route path="/besserstellung" element={<Besserstellung />} />
          <Route path="/cost-average-effect" element={<CostAverageEffect />} />
          <Route
            path="/strategic-eternal-value"
            element={<StrategicEternalValue />}
          />
          <Route path="/programmliste" element={<Programmliste />} />
          <Route path="/vorsorge" element={<Vorsorge />} />
          <Route path="/kindersparplan" element={<Kindersparplan />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/cancelAppointment" element={<CancelAppointment />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
        <Contact />
        <Footer />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
