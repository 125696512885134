import React, { createContext, useReducer } from "react";

const parseTime = (date) => {
  // date = "2024-06-04 13:00"
  const time = date.split(" ")[1]; // 13:00
  const hours = time.split(":")[0]; // 13
  const amOrPm = hours >= 12 ? "PM" : "AM";
  return `${hours % 12 || 12} ${amOrPm}`;
};

const mapChartData = (data) => {
  // [
  //     {
  //       gold: 20,
  //       silver: 30,
  //       palladium: 20,
  //       platinum: 30,
  //       time: "12 PM",
  //     },
  //     {
  //       gold: 200,
  //       silver: 100,
  //       palladium: 200,
  //       platinum: 10,
  //       time: "1 AM",
  //     },
  // ];
  const chartData = {
    time: [],
    gold: [],
    silver: [],
    platinum: [],
    palladium: [],
  };
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    chartData.time.push(parseTime(item.time));
    chartData.gold.push(item.gold);
    chartData.silver.push(item.silver);
    chartData.platinum.push(item.platinum);
    chartData.palladium.push(item.palladium);
  }
  return chartData;
};

const mapCalendarData = (data) => {
  let availableTimeslots = [];
  for (let i = 0; i < data.length; i++) {
    const availableTimesPerUser = data[i].available_times;
    const userId = data[i].id;
    for (let j = 0; j < availableTimesPerUser.length; j++) {
      const { start_date, end_date } = availableTimesPerUser[j];
      availableTimeslots.push({
        user_id: userId,
        startTime: new Date(start_date),
        endTime: new Date(end_date),
      });
    }
  }
  return availableTimeslots;
};

const initialState = {
  baseUrl: "https://cms.edelwealth.ch/api/",
  mediaBaseUrl: "https://cms.edelwealth.ch/storage/",
  lang: localStorage.getItem("lang") || "en",
  home: {},
  header: [],
  footer: {},
  unternehmen: {},
  vorteile: {},
  edelmetalle: {},
  wertsteigerung: {},
  wirtschaftlicher_nutzen: {},
  service: {},
  sicherheit_lagerung: {},
  besserstellung: {},
  cost_average_effect: {},
  strategic_eternal_value: {},
  programmliste: {},
  vorsorge: {},
  kindersparplan: {},
  faq: {},
  activePage: {},
  salutations: [],
  calendarData: [],
  chartData: {
    time: [],
    gold: [],
    silver: [],
    platinum: [],
    palladium: [],
  },
};

const defaultContextValue = {
  state: initialState,
  dispatch: () => initialState, // This is a placeholder function that does nothing
};

export const AppContext = createContext(defaultContextValue);

// Define the reducer function
const appReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "setHomeData":
      return {
        ...state,
        home: payload,
      };
    case "setHeaderData":
      return {
        ...state,
        header: payload,
      };
    case "setUnternehmenData":
      return {
        ...state,
        unternehmen: payload,
      };
    case "setVorteileData":
      return {
        ...state,
        vorteile: payload,
      };
    case "setEdelmetalleData":
      return {
        ...state,
        edelmetalle: payload,
      };
    case "setWertsteigerungData":
      return {
        ...state,
        wertsteigerung: payload,
      };
    case "setWirtschaftlicher-nutzenData":
      return {
        ...state,
        wirtschaftlicher_nutzen: payload,
      };
    case "setServiceData":
      return {
        ...state,
        service: payload,
      };
    case "setSicherheit-lagerungData":
      return {
        ...state,
        sicherheit_lagerung: payload,
      };
    case "setBesserstellungData":
      return {
        ...state,
        besserstellung: payload,
      };
    case "setCost-average-effectData":
      return {
        ...state,
        cost_average_effect: payload,
      };
    case "setStrategic-eternal-valueData":
      return {
        ...state,
        strategic_eternal_value: payload,
      };
    case "setProgrammlisteData":
      return {
        ...state,
        programmliste: payload,
      };
    case "setVorsorgeData":
      return {
        ...state,
        vorsorge: payload,
      };
    case "setKindersparplanData":
      return {
        ...state,
        kindersparplan: payload,
      };
    case "setFaqData":
      return {
        ...state,
        faq: payload,
      };
    case "setFooterData":
      return {
        ...state,
        footer: payload,
      };
    case "setLang":
      localStorage.setItem("lang", payload);
      return {
        ...state,
        lang: payload,
      };
    case "setActivePageData":
      return {
        ...state,
        activePage: payload,
      };
    case "setSalutationsData":
      return {
        ...state,
        salutations: payload,
      };
    case "setChartData":
      return {
        ...state,
        chartData: mapChartData(payload),
      };
    case "setCalendarData":
      return {
        ...state,
        calendarData: mapCalendarData(payload),
      };
    default:
      return state;
  }
};

// Create a context provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const contextValue = {
    state,
    dispatch,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
