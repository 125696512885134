import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/Dispatchhelper";
import Page from "../../components/page";

function Service() {
  const {
    state: { baseUrl, service },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();

  useEffect(() => {
    const slug = "Service";
    axios.get(`${baseUrl}contents/${slug}`).then((response) => {
      dispatchAction("setServiceData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service container">
      <Page data={service} />
    </div>
  );
}

export default Service;
