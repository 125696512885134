import React, { useState } from "react";
import plusIcon from "../../assets/img/plus.svg";
import minusIcon from "../../assets/img/minus.svg";
import { Fade } from "react-awesome-reveal";

function Fieldset({ title, description, direction = "" }) {
  const [isFieldsetOpen, setIsFieldsetOpen] = useState(false);
  return (
    <Fade triggerOnce={true} direction={direction}>
      <div className={`fieldset ${isFieldsetOpen ? "expanded" : ""}`}>
        <div
          className="fieldset-header"
          onClick={() => setIsFieldsetOpen(!isFieldsetOpen)}
        >
          <div
            className="fieldset-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <img
            src={isFieldsetOpen ? minusIcon : plusIcon}
            alt="toggle fieldset"
          />
        </div>
        <div
          className="fieldset-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Fade>
  );
}

export default Fieldset;
