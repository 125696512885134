import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/Dispatchhelper";
import Logo from "../../assets/img/logo-light.svg";
import LangImg_en from "../../assets/img/lang-en.png";
import LangImg_de from "../../assets/img/lang-de.png";
import LangImg_hu from "../../assets/img/lang-hu.png";
import ArrowDownImg from "../../assets/img/arrow-down.svg";
import { ReactComponent as ArrowDownTag } from "../../assets/img/arrow-down.svg";
import menuIcon from "../../assets/img/menu-icon.png";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const {
    state: { baseUrl, header, lang, activePage },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const { pathname } = useLocation();
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [openDropDown, setOpenDropDown] = useState("");
  const [openMobileMenuClassName, setOpenMobileMenuClassName] = useState("");

  useEffect(() => {
    axios.get(`${baseUrl}menu`).then((response) => {
      dispatchAction("setHeaderData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveHeaderItem = ({ pathname, header }) => {
    if (!header.length) return;
    const getActivePage = header?.filter((headerItem) => {
      if (pathname === "/") return headerItem.slug === "edelwealth";
      return "/" + headerItem.slug === pathname;
    });
    if (getActivePage.length) {
      dispatchAction("setActivePageData", getActivePage[0]);
      return;
    }
    for (const i in header) {
      const childrenHeaders = header[i].children;
      if (!childrenHeaders) return;
      if (getActiveHeaderItem({ pathname, header: childrenHeaders })) return;
    }
  };

  useEffect(() => {
    getActiveHeaderItem({ pathname, header });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, header]);

  // --- handle language dropdown
  const getLangList = () => {
    return ["en", "de", "hu"];
  };
  const getLangImg = (language) => {
    switch (language) {
      case "en":
      default:
        return LangImg_en;
      case "de":
        return LangImg_de;
      case "hu":
        return LangImg_hu;
    }
  };
  const handleSwitchLang = (lng) => {
    setOpenDropDown("");
    dispatchAction("setLang", lng);
  };
  const renderDesktopLangDropDown = () => {
    return (
      <div className="header-lang desktop">
        <span className="header-lang-chosen">
          <img
            className="header-lang-img"
            src={getLangImg(lang)}
            alt="Language chosen"
          />
          <img
            className="header-lang-chosen-arr"
            src={ArrowDownImg}
            alt="open dropdown"
          />
        </span>
        <ul className="header-lang-list">
          {getLangList().map((lng, i) => {
            if (lng !== lang) {
              return (
                <li
                  key={`header-lang-list-item-${i}`}
                  onClick={() => handleSwitchLang(lng)}
                >
                  <img
                    className="header-lang-img"
                    src={getLangImg(lng)}
                    alt={`Choose Lang ${lng}`}
                  />
                </li>
              );
            }
            return (
              <React.Fragment
                key={`header-lang-list-item-${i}`}
              ></React.Fragment>
            );
          })}
        </ul>
      </div>
    );
  };

  // --- handle navigation
  const addActiveNavItemClassName = ({ slug, children }) => {
    const isActiveMenuLink = activePage?.slug === slug;
    const isChildActive =
      children?.filter((child) => activePage.slug === child.slug).length > 0;
    return isActiveMenuLink || isChildActive ? "active" : "";
  };

  const renderDesktopNavigation = () => {
    return (
      header && (
        <nav className="header-nav desktop">
          <ul className="header-nav-list">
            {header.map((headerItem, i) => (
              <li
                key={`header-nav-list-item-${i}`}
                className="header-nav-list-item"
              >
                <Link
                  className={addActiveNavItemClassName(headerItem)}
                  to={`/${headerItem.slug}`}
                >
                  {headerItem[`title_${lang}`]}
                  {headerItem.children.length !== 0 && (
                    <img
                      className="header-nav-list-item-arr"
                      src={ArrowDownImg}
                      alt="open dropdown"
                    />
                  )}
                </Link>
                {headerItem.children.length !== 0 && (
                  <ul className="header-nav-list-item-dropdown">
                    {headerItem.children.map((dropDownItem, i) => (
                      <li
                        key={`header-nav-list-item-dropdown-item-${i}`}
                        className="header-nav-list-item-dropdown-item"
                      >
                        <Link
                          className={addActiveNavItemClassName(dropDownItem)}
                          to={`/${dropDownItem.slug}`}
                        >
                          {dropDownItem[`title_${lang}`]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )
    );
  };

  // --- handle mobile navigation
  const handleMobileNavigationOpen = (isOpen) => {
    if (isOpen) {
      setIsOpenMobileMenu(isOpen);
      setTimeout(() => {
        setOpenMobileMenuClassName("open");
      }, 50);
    } else {
      setOpenDropDown("");
      setOpenMobileMenuClassName("");
      setTimeout(() => {
        setIsOpenMobileMenu(false);
      }, 500);
    }
  };
  const renderMobileLangDropDown = () => {
    return (
      <div
        className={`header-mobile-nav-drawer-wrapper-content-dropdown lang ${
          openDropDown === "lang" && "open"
        }`}
      >
        <div className="menulink">
          <img src={getLangImg(lang)} alt="language selected" />
          <ArrowDownTag
            className="open-dropdown"
            onClick={() =>
              setOpenDropDown(openDropDown === "lang" ? "" : "lang")
            }
          />
        </div>
        <div className="submenulinks">
          {getLangList().map((lng, i) => {
            if (lng !== lang) {
              return (
                <div
                  className="submenulink"
                  key={`lang-submenu-links-${i}`}
                  onClick={() => handleSwitchLang(lng)}
                >
                  <img src={getLangImg(lng)} alt={`Choose Lang ${lng}`} />
                </div>
              );
            }
            return (
              <React.Fragment key={`lang-submenu-links-${i}`}></React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };
  const renderMobileNavDropDown = () => {
    return header.map((headerItem, i) => (
      <div
        key={"mobile-nav-dropdown-" + i + "-" + headerItem.slug}
        className={`header-mobile-nav-drawer-wrapper-content-dropdown nav ${
          openDropDown === headerItem.slug && "open"
        } ${addActiveNavItemClassName(headerItem)}`}
      >
        <div className={`menulink`}>
          <Link
            onClick={() => handleMobileNavigationOpen(false)}
            to={`/${headerItem.slug}`}
          >
            {headerItem[`title_${lang}`]}
          </Link>
          {headerItem.children.length !== 0 && (
            <ArrowDownTag
              className="open-dropdown"
              onClick={() =>
                setOpenDropDown(
                  openDropDown === headerItem.slug ? "" : headerItem.slug
                )
              }
            />
          )}
        </div>
        {headerItem.children.length !== 0 && (
          <div className="submenulinks">
            {headerItem.children.map((dropDownItem, i) => (
              <div
                key={"mobile-nav-dropdown-items-" + i + "-" + dropDownItem.slug}
                className="submenulink"
              >
                <Link
                  onClick={() => handleMobileNavigationOpen(false)}
                  to={`/${dropDownItem.slug}`}
                >
                  {dropDownItem[`title_${lang}`]}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };
  const renderMobileNavigation = () => {
    return (
      <>
        {
          <div className="header-mobile-nav mobile">
            <img
              onClick={() => handleMobileNavigationOpen(true)}
              alt="open mobile navigation menu"
              src={menuIcon}
            />
            {isOpenMobileMenu && (
              <div
                className={`header-mobile-nav-drawer ${openMobileMenuClassName}`}
              >
                <div
                  className="header-mobile-nav-drawer-drop"
                  onClick={() => handleMobileNavigationOpen(false)}
                ></div>
                <div className="header-mobile-nav-drawer-wrapper">
                  <div className="header-mobile-nav-drawer-wrapper-content">
                    <div className="header-mobile-nav-drawer-wrapper-content-logo">
                      <Link
                        to="/"
                        onClick={() => handleMobileNavigationOpen(false)}
                      >
                        <img src={Logo} alt="logo" />
                      </Link>
                    </div>
                    {renderMobileLangDropDown()}
                    {renderMobileNavDropDown()}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </>
    );
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        {renderDesktopNavigation()}
        {renderDesktopLangDropDown()}

        {renderMobileNavigation()}
      </div>
    </header>
  );
}

export default Header;
