import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/Dispatchhelper";
import contactIcon from "../../assets/img/contact-icon.png";
import closeIcon from "../../assets/img/close-icon.png";
import { ScheduleMeeting } from "react-schedule-meeting";
import translate from "../../services/translation.json";

export default function Contact() {
  const {
    state: { baseUrl, salutations, lang, calendarData },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();

  useEffect(() => {
    axios.get(`${baseUrl}salutation`).then((response) => {
      dispatchAction("setSalutationsData", response?.data?.data);
    });
    fetchCalendarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isBookAppointmentOpen, setIsBookAppointmentOpen] = useState(false);
  const [isAppointmentContactDetailsOpen, setAppointmentContactDetailsOpen] =
    useState(false);
  const [isValidatingForm, setIsValidatingForm] = useState(false);
  const [shouldNotify, setShouldNotify] = useState({
    msg: "",
    type: "",
  });
  const contactUsText = translate[lang].contactUs;
  const bookAppointmentText = translate[lang].bookAppointment;

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    salutation: "",
    email: "",
    phone_number: "",
    messages: "",
  });

  const [appointmentFormData, setAppointmentFormData] = useState({
    first_name: "",
    last_name: "",
    salutation: "",
    email: "",
    user_id: "",
    booking_start: "",
    booking_end: "",
    note: "",
    language: lang,
  });

  const fetchCalendarData = () => {
    axios.get(`${baseUrl}calendar`).then((response) => {
      dispatchAction("setCalendarData", response?.data?.data);
    });
  };

  const isFormValid = (formData) => {
    let formDataValid = true;
    Object.keys(formData)
      .filter(
        (formItem) => formItem !== "booking_start" && formItem !== "booking_end"
      )
      .forEach((formItem) => {
        if (!formData[formItem].toString().trim()) {
          formDataValid = false;
        }
      });

    return formDataValid;
  };

  const resetAndCloseContactForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      salutation: "",
      email: "",
      phone_number: "",
      messages: "",
    });

    setIsValidatingForm(false);
    setIsContactFormOpen(false);
  };

  const notify = ({ type, msg }) => {
    setShouldNotify({ msg, type });
    setTimeout(() => {
      setShouldNotify({ msg: "", type: "" });
    }, 4000);
  };

  const submit = () => {
    setIsValidatingForm(true);
    if (!isFormValid(formData)) {
      return;
    }
    setIsSubmitting(true);
    axios
      .post(`${baseUrl}contact-form`, formData)
      .then(() => {
        notify({ msg: "Form submitted successfully", type: "success" });
        setIsSubmitting(false);
        resetAndCloseContactForm();
      })
      .catch((err) => {
        notify({
          msg: err.response.data.message + ": " + err.response.data.data,
          type: "error",
        });
        setIsSubmitting(false);
      });
  };

  const renderContactForm = () => {
    return (
      <div className="contact-form-modal">
        <div
          className={`notify ${shouldNotify.type} ${
            shouldNotify.type ? "notify-show" : ""
          }`}
        >
          {shouldNotify.msg}
        </div>
        <div
          className={`contact-form-container ${
            isValidatingForm ? "validate-form" : ""
          }`}
        >
          <div
            className="contact-form-close"
            onClick={resetAndCloseContactForm}
          >
            <img alt="close contact form" src={closeIcon} />
          </div>
          <div className="contact-form-title">{contactUsText}</div>

          <div className="contact-form-content">
            <div className="contact-form-group">
              <div className="contact-form-group-items w-50">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].name}
                  </label>

                  <input
                    type="text"
                    value={formData.first_name}
                    onChange={(e) =>
                      setFormData({ ...formData, first_name: e.target.value })
                    }
                    className={`contact-form-group-item-input ${
                      !formData.first_name.trim() ? "error" : ""
                    }`}
                  />
                  <small>{translate[lang].firstName}</small>
                </div>
                <div className="contact-form-group-item">
                  <input
                    type="text"
                    className={`contact-form-group-item-input ${
                      !formData.last_name.trim() ? "error" : ""
                    }`}
                    value={formData.last_name}
                    onChange={(e) =>
                      setFormData({ ...formData, last_name: e.target.value })
                    }
                  />
                  <small>{translate[lang].lastName}</small>
                </div>
              </div>
            </div>

            <div className="contact-form-group">
              <div className="contact-form-group-items w-50">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].email}
                  </label>
                  <input
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      })
                    }
                    type="email"
                    className={`contact-form-group-item-input ${
                      !formData.email.trim() ? "error" : ""
                    }`}
                  />
                  <small>example@example.com</small>
                </div>
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].phoneNumber}
                  </label>

                  <input
                    value={formData.phone_number}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        phone_number: e.target.value,
                      })
                    }
                    type="tel"
                    className={`contact-form-group-item-input ${
                      !formData.phone_number.trim() ? "error" : ""
                    }`}
                  />
                  <small>Example +3 90 00 00 00 00</small>
                </div>
              </div>
            </div>

            <div className="contact-form-group">
              <div className="contact-form-group-items">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].title}
                  </label>
                  <select
                    value={formData.salutation}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        salutation: e.target.value,
                      })
                    }
                    className={`contact-form-group-item-input ${
                      !formData.salutation.trim() ? "error" : ""
                    }`}
                  >
                    <option value="">{translate[lang].chooseTitle}</option>
                    {salutations.length && (
                      <React.Fragment>
                        {salutations.map((s) => (
                          <option
                            key={"salutation-" + s.id}
                            value={s[`salutation_${lang}`]}
                          >
                            {s[`salutation_${lang}`]}
                          </option>
                        ))}
                      </React.Fragment>
                    )}
                  </select>
                  <small>Example: Mr.</small>
                </div>
              </div>
            </div>

            <div className="contact-form-group">
              <div className="contact-form-group-items">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].message}
                  </label>
                  <textarea
                    value={formData.messages}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        messages: e.target.value,
                      })
                    }
                    type="text"
                    className={`contact-form-group-item-input ${
                      !formData.messages.trim() ? "error" : ""
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className="contact-form-submission">
              <span
                className="button"
                onClick={() => {
                  if (!isSubmitting) submit();
                }}
              >
                {translate[lang].submit}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(new Date(date).getHours() + hours));
  }

  function addMinutesToDate(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }

  const resetAndCloseAppointment = () => {
    setAppointmentFormData({
      first_name: "",
      last_name: "",
      salutation: "",
      email: "",
      user_id: "",
      booking_start: "",
      booking_end: "",
      note: "",
      language: lang,
    });
    setIsValidatingForm(false);
    setAppointmentContactDetailsOpen(false);
    setIsBookAppointmentOpen(false);
  };

  const parseDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleTimeslotClicked = (startTimeEventEmit) => {
    // reset the calendar
    startTimeEventEmit.resetDate();
    startTimeEventEmit.resetSelectedTimeState();
    const user_id = startTimeEventEmit.availableTimeslot.user_id;
    const booking_start = parseDate(startTimeEventEmit.startTime);
    // add one hour to appointment and 30 mins break
    const booking_end = parseDate(
      addMinutesToDate(addHoursToDate(startTimeEventEmit.startTime, 1), 30)
    );
    // get the selected time needed and user id
    setAppointmentFormData({
      ...appointmentFormData,
      booking_start,
      booking_end,
      user_id,
    });
    // close this form
    setIsBookAppointmentOpen(false);
    // open the appointment contact details form
    setAppointmentContactDetailsOpen(true);
  };

  const renderBookAppointmentForm = () => {
    return (
      <div className="contact-form-modal">
        <div
          className={`contact-form-container book-appointment ${
            isValidatingForm ? "validate-form" : ""
          }`}
        >
          <div
            className="contact-form-close"
            onClick={() => resetAndCloseAppointment()}
          >
            <img alt="close contact form" src={closeIcon} />
          </div>
          <div className="contact-form-title">{bookAppointmentText}</div>

          <div className="contact-form-content">
            <ScheduleMeeting
              borderRadius={10}
              primaryColor="#BF8513"
              eventDurationInMinutes={90}
              onStartTimeSelect={handleTimeslotClicked}
              availableTimeslots={calendarData}
              startTimeListStyle={"scroll-list"}
              lang_cancelButtonText={translate[lang].cancel}
              lang_confirmButtonText={translate[lang].confirm}
              lang_emptyListText={translate[lang].noTimesAvailable}
              lang_goToNextAvailableDayText={translate[lang].nextAvailable}
              lang_noFutureTimesText={translate[lang].noFutureTimesAvailable}
              lang_selectedButtonText={`${translate[lang].selected}:`}
            />
          </div>
        </div>
      </div>
    );
  };

  const scheduleAppointment = () => {
    setIsValidatingForm(true);
    if (!isFormValid(appointmentFormData)) {
      return;
    }
    setIsSubmitting(true);
    axios
      .post(`${baseUrl}book`, appointmentFormData)
      .then(() => {
        notify({ msg: "Appointment booked successfully", type: "success" });
        setIsSubmitting(false);
        resetAndCloseAppointment();
        fetchCalendarData();
      })
      .catch((err) => {
        notify({
          msg: err.response.data.message + ": " + err.response.data.data,
          type: "error",
        });
        setIsSubmitting(false);
      });
  };

  const renderAppointmentContactDetailsForm = () => {
    return (
      <div className="contact-form-modal">
        <div
          className={`notify ${shouldNotify.type} ${
            shouldNotify.type ? "notify-show" : ""
          }`}
        >
          {shouldNotify.msg}
        </div>
        <div
          className={`contact-form-container ${
            isValidatingForm ? "validate-form" : ""
          }`}
        >
          <div
            className="contact-form-close"
            onClick={resetAndCloseAppointment}
          >
            <img alt="close contact form" src={closeIcon} />
          </div>
          <div className="contact-form-title">
            {translate[lang].contactDetails}
          </div>

          <div className="contact-form-content">
            <div className="contact-form-group">
              <div className="contact-form-group-items w-50">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].name}
                  </label>

                  <input
                    type="text"
                    value={appointmentFormData.first_name}
                    onChange={(e) =>
                      setAppointmentFormData({
                        ...appointmentFormData,
                        first_name: e.target.value,
                      })
                    }
                    className={`contact-form-group-item-input ${
                      !appointmentFormData.first_name.trim() ? "error" : ""
                    }`}
                  />
                  <small>{translate[lang].firstName}</small>
                </div>
                <div className="contact-form-group-item">
                  <input
                    type="text"
                    className={`contact-form-group-item-input ${
                      !appointmentFormData.last_name.trim() ? "error" : ""
                    }`}
                    value={appointmentFormData.last_name}
                    onChange={(e) =>
                      setAppointmentFormData({
                        ...appointmentFormData,
                        last_name: e.target.value,
                      })
                    }
                  />
                  <small>{translate[lang].lastName}</small>
                </div>
              </div>
            </div>

            <div className="contact-form-group">
              <div className="contact-form-group-items w-50">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].email}
                  </label>
                  <input
                    value={appointmentFormData.email}
                    onChange={(e) =>
                      setAppointmentFormData({
                        ...appointmentFormData,
                        email: e.target.value,
                      })
                    }
                    type="email"
                    className={`contact-form-group-item-input ${
                      !appointmentFormData.email.trim() ? "error" : ""
                    }`}
                  />
                  <small>example@example.com</small>
                </div>
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].title}
                  </label>
                  <select
                    value={appointmentFormData.salutation}
                    onChange={(e) =>
                      setAppointmentFormData({
                        ...appointmentFormData,
                        salutation: e.target.value,
                      })
                    }
                    className={`contact-form-group-item-input ${
                      !appointmentFormData.salutation.trim() ? "error" : ""
                    }`}
                  >
                    <option value="">{translate[lang].chooseTitle}</option>
                    {salutations.length && (
                      <React.Fragment>
                        {salutations.map((s) => (
                          <option
                            key={"salutation-" + s.id}
                            value={s[`salutation_${lang}`]}
                          >
                            {s[`salutation_${lang}`]}
                          </option>
                        ))}
                      </React.Fragment>
                    )}
                  </select>
                  <small>Example: Mr.</small>
                </div>
              </div>
            </div>

            <div className="contact-form-group">
              <div className="contact-form-group-items">
                <div className="contact-form-group-item">
                  <label className="contact-form-group-title">
                    {translate[lang].notes}
                  </label>
                  <textarea
                    value={appointmentFormData.note}
                    onChange={(e) =>
                      setAppointmentFormData({
                        ...appointmentFormData,
                        note: e.target.value,
                      })
                    }
                    type="text"
                    className={`contact-form-group-item-input ${
                      !appointmentFormData.note.trim() ? "error" : ""
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className="contact-form-submission">
              <span
                className="button"
                onClick={() => {
                  if (!isSubmitting) scheduleAppointment();
                }}
              >
                {translate[lang].schedule}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="contact-form-button" onClick={() => setIsOpen(!isOpen)}>
        <img alt="open contact form" src={contactIcon} />
        {isOpen && (
          <div className="contact-us-menu">
            <p onClick={() => setIsContactFormOpen(true)}>{contactUsText}</p>
            <p onClick={() => setIsBookAppointmentOpen(true)}>
              {bookAppointmentText}
            </p>
          </div>
        )}
      </div>
      <div></div>
      {isContactFormOpen && renderContactForm()}
      {isBookAppointmentOpen && renderBookAppointmentForm()}
      {isAppointmentContactDetailsOpen && renderAppointmentContactDetailsForm()}
    </React.Fragment>
  );
}
