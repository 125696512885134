import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/Dispatchhelper";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo-light.svg";
import instagram from "../../assets/img/instagram.png";
import facebook from "../../assets/img/facebook.png";
import linkedIn from "../../assets/img/linkedIn.png";
import locationIcon from "../../assets/img/location-icon.png";
import mailIcon from "../../assets/img/mail-icon.png";
import phoneIcon from "../../assets/img/phone-icon.png";
import arrowRight from "../../assets/img/arrow-right.png";

function Footer() {
  const quickLinksSlugs = ["edelmetalle", "service", "faq"];
  const {
    state: { baseUrl, header, lang, footer },
  } = useContext(AppContext);

  const { dispatchAction } = useDispatchHelpers();

  useEffect(() => {
    axios.get(`${baseUrl}general-information`).then((response) => {
      dispatchAction("setFooterData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="footer-lists">
          <div className="footer-list">
            <div className="footer-list-title">
              Contact
              <br />
              Info
            </div>
            <ul className="footer-list-content">
              <li>
                <img src={phoneIcon} alt="phone number" />
                <span className="footer-phonenumbers">
                  {Object.keys(footer).length &&
                    JSON.parse(footer.phone_numbers).map(
                      ({ phone_numbers }) => (
                        <a
                          key={`footer-phone-nbs-${phone_numbers}`}
                          rel="noreferrer"
                          href={`tel:${phone_numbers}`}
                          target="_blank"
                        >
                          {phone_numbers}
                        </a>
                      )
                    )}
                </span>
              </li>
              <li>
                <img src={mailIcon} alt="email" />
                <a
                  rel="noreferrer"
                  href={`mailto:${footer.email}`}
                  target="_blank"
                >
                  {footer.email}
                </a>
              </li>
              <li>
                <img src={locationIcon} alt="address" />
                <span>{footer.address}</span>
              </li>
            </ul>
          </div>
          <div className="footer-list">
            <div className="footer-list-title">
              Quick
              <br />
              Links
            </div>
            <ul className="footer-list-content">
              {header
                .filter((headerItem) =>
                  quickLinksSlugs.includes(headerItem.slug)
                )
                .map((quicklink) => (
                  <li key={`footer-quicklinks-${quicklink.slug}`}>
                    <img src={arrowRight} alt="arrow icon" />
                    <Link to={`/${quicklink.slug}`}>
                      {quicklink[`title_${lang}`]}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div
          className="footer-rights"
          dangerouslySetInnerHTML={{ __html: footer[`privacy_${lang}`] }}
        />
        <div className="footer-media">
          <a rel="noreferrer" href={footer.linkedIn} target="_blank">
            <img src={linkedIn} alt="linkedin link" />
          </a>
          <a rel="noreferrer" href={footer.facebook} target="_blank">
            <img src={facebook} alt="facebook link" />
          </a>
          <a rel="noreferrer" href={footer.instagram} target="_blank">
            <img src={instagram} alt="instagram link" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
