import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/Dispatchhelper";
import Banner from "../../components/banner/banner";
import Fieldset from "./../../components/fieldset/fieldset";
import WebLoader from "../../components/webLoader/WebLoader";
import { Fade } from "react-awesome-reveal";

function FAQ() {
  const {
    state: { baseUrl, mediaBaseUrl, faq, activePage, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataLoading = !Object.keys(faq).length > 0;
  useEffect(() => {
    const slug = "faq";
    axios.get(`${baseUrl}contents/${slug}`).then((response) => {
      const banners = response?.data?.data?.banners;
      axios.get(`${baseUrl}${slug}`).then((response) => {
        dispatchAction("setFaqData", { faqs: response?.data?.data, banners });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFaqs = (faqs, direction) => {
    return (
      faqs &&
      faqs.length && (
        <div className="faq-wrapper">
          {faqs.map((fq, i) => (
            <Fieldset
              direction={direction}
              key={`faq-${fq[`title_${lang}`] + i}`}
              title={fq[`title_${lang}`]}
              description={fq[`description_${lang}`]}
            />
          ))}
        </div>
      )
    );
  };

  const getEvenOddFaqs = (faqs) => {
    if (!faqs) return [];
    const even = faqs.filter((fq, i) => i % 2 === 0);
    const odd = faqs.filter((fq, i) => i % 2 !== 0);
    return [even, odd];
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={isDataLoading} />
      {!isDataLoading && (
        <div className="faq container">
          {faq?.banners && (
            <Banner
              mediaUrl={mediaBaseUrl + faq.banners[0].path}
              title={faq.banners[0][`title_${lang}`]}
              type={faq.banners[0].type}
            />
          )}
          <Fade triggerOnce={true}>
            <div id="page-container">
              <h1 className="page-title">{activePage[`title_${lang}`]}</h1>
              <div className="faqs-wrapper">
                {getEvenOddFaqs(faq?.faqs).map((evenOddFaqs, i) => (
                  <React.Fragment key={"faq-wrapper-" + i}>
                    {renderFaqs(evenOddFaqs, i % 2 === 0 ? "left" : "right")}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Fade>
        </div>
      )}
    </React.Fragment>
  );
}

export default FAQ;
