import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./cancelAppointment.scss";
import translate from "../../services/translation.json";
import { AppContext } from "../../store/appContext";
import axios from "axios";

const CancelAppointment = () => {
  // Add your component logic here
  const navigate = useNavigate();
  const {
    state: { baseUrl, lang },
  } = useContext(AppContext);
  const [queryParameters] = useSearchParams();
  const id = queryParameters.get("id");
  const handleCancelAppointment = () => {
    axios
      .post(`${baseUrl}book/delete/${id}`, {
        uuid: id,
        language: lang,
      })
      .then(() => {
        navigate("/");
      });
  };

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className={`section home-style cancel-appointment-page`}>
      <div className={`section-title page-title`}>{translate[lang].cancel}</div>
      <div className={`section-content single`}>
        <p>{translate[lang].cancelAppointmentText}</p>
        <div>
          <button className="section-link" onClick={handleCancelAppointment}>
            {translate[lang].confirm}
          </button>
          <button className="section-link" onClick={() => navigate("/")}>
            {translate[lang].cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelAppointment;
