import React, { useEffect, useRef, memo, useContext } from "react";
import { AppContext } from "../../store/appContext";
import translate from "../../services/translation.json";
import { Fade } from "react-awesome-reveal";

function TradingViewWidget() {
  const container = useRef();
  const {
    state: { lang },
  } = useContext(AppContext);
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "live-chart-script";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "symbols": [
            [
              "TVC:GOLD|1D|EUR"
            ],
            [
              "TVC:SILVER|1D|EUR"
            ],
            [
              "TVC:PLATINUM|1D|EUR"
            ],
            [
              "TVC:PALLADIUM|1D|EUR"
            ]
          ],
          "chartOnly": false,
          "width": "100%",
          "height": "430",
          "locale": "en",
          "colorTheme": "dark",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "Arial, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "backgroundColor": "rgba(7, 28, 74, 1)",
          "lineWidth": 2,
          "lineType": 0,
          "dateRanges": [
            "1d|1",
            "1m|30",
            "3m|60",
            "12m|1D",
            "60m|1W",
            "all|1M"
          ],
          "lineColor": "rgba(191, 133, 19, 1)",
          "topColor": "rgba(191, 133, 19, 1)",
          "bottomColor": "rgba(7, 28, 74, 1)",
          "dateFormat": "MMM dd, yyyy",
          "timeHoursFormat": "12-hours"
        }`;

    if (!document.getElementById("live-chart-script")) {
      container.current.appendChild(script);
    }
  }, []);

  return (
    <Fade triggerOnce={true} className="section-graph">
      <div className={`section home-style`}>
        <div className={`section-title page-title`}>
          {translate[lang].liveChart}
        </div>
        <div className={`section-content`}>
          <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default memo(TradingViewWidget);
