import React from "react";

function Quote({ title, description }) {
  return (
    <div className={`quote`}>
      <div
        className={`page-title quote-title`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div
        className="quote-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
}

export default Quote;
