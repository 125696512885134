// dispatchHelpers.ts
import { AppContext } from "../store/appContext";
import { useContext } from "react";

export const useDispatchHelpers = () => {
  const { dispatch } = useContext(AppContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatchAction = (type, payload) => {
    dispatch({ type, payload });
  };

  return { dispatchAction };
};
