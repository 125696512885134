import { useEffect, useState} from "react";
import logo from "./../../assets/img/logo-light.svg";


function WebLoader({isLoading}) {
  const [shouldRemoveLoader, setShouldRemoveLoader] = useState(true); // by default loader is removed
  useEffect(() => {
    // content is still loading, loader should be visible
    if(isLoading){
      setShouldRemoveLoader(false);
      return;
    }
    // content is loaded remove loader
    setTimeout(() => {
      setShouldRemoveLoader(true);
    }, 1000);
  }, [isLoading]);

  return (
    !shouldRemoveLoader &&(
      <div
        className={`web-loader`}
      >
        <img src={logo} alt="Edelwealth logo" />
      </div>
    )
  );
}

export default WebLoader;