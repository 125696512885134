import React from "react";
import { ReactComponent as ArrowDownTag } from "../../assets/img/arrow-down.svg";

function Banner({ type, mediaUrl, title }) {
  return (
    <>
      {type === "Image" && (
        <div
          className="banner-wrapper banner-wrapper-image"
          style={{ backgroundImage: `url("${mediaUrl}")` }}
        >
          <div
            className="banner-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="banner-scroll">
            <a href="#page-container">
              Scroll Down <ArrowDownTag className="banner-scroll-arr" />
            </a>
          </div>
        </div>
      )}
      {type === "Video" && (
        <div className="banner-wrapper banner-wrapper-video">
          <video autoplay>
            <source src={mediaUrl} />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </>
  );
}

export default Banner;
