import React from "react";
import { Fade } from "react-awesome-reveal";

function Section({
  title,
  description,
  imgUrl,
  isHomePageStyle,
  isLinkable = false,
}) {
  return (
    <div className={`section ${isHomePageStyle ? "home-style" : ""}`}>
      <div
        className={`section-title ${isHomePageStyle ? "page-title" : ""}`}
        dangerouslySetInnerHTML={{ __html: title }}
      ></div>
      <div className={`section-content ${imgUrl ? "" : "single"}`}>
        {imgUrl && (
          <Fade
            triggerOnce={true}
            direction={isHomePageStyle ? "right" : ""}
            className="section-image"
          >
            <img src={imgUrl} alt={title} />
          </Fade>
        )}
        <Fade
          triggerOnce={true}
          direction={isHomePageStyle ? "left" : ""}
          className="section-description"
        >
          <div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {isLinkable && (
              <a className="section-link" href={`/${isLinkable}`}>
                Read More
              </a>
            )}
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default Section;
