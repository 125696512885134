import React from "react";
import { Fade } from "react-awesome-reveal";

function Card({
  title,
  description,
  imgUrl,
  isHomePageStyle,
  isLinkable = false,
  direction = "",
}) {
  return (
    <Fade
      triggerOnce={true}
      direction={direction}
      className={`card ${isHomePageStyle ? "home-style" : ""} ${
        imgUrl && !isHomePageStyle ? "with-bg" : ""
      }`}
      style={
        imgUrl &&
        !isHomePageStyle && {
          backgroundImage: `url("${encodeURI(imgUrl)}")`,
        }
      }
    >
      <React.Fragment>
        {imgUrl && isHomePageStyle && (
          <div className="card-image">
            <img src={imgUrl} alt={description} />
          </div>
        )}
        <div className={`card-content`}>
          {title && (
            <div
              className={`card-title`}
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          )}
          <div className="card-description">
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {isLinkable && (
              <a className="card-link" href={`/${isLinkable}`}>
                Read More...
              </a>
            )}
          </div>
        </div>
      </React.Fragment>
    </Fade>
  );
}

export default Card;
