import React from "react";

const getTableColumnNames = (tableData) => {
  const row = tableData[0];
  return Object.keys(row).map((headerName) =>
    headerName.toLocaleLowerCase().split("_").join(" ")
  );
};

function Table({ data }) {
  if (!data) return;
  const tableData = JSON.parse(data);
  const tableHeaders = getTableColumnNames(tableData);
  return (
    tableData && (
      <div className="table">
        <table>
          <thead>
            <tr className="table-header">
              {tableHeaders.map((headerName, i) => (
                <td
                  className={`table-cell ${
                    tableHeaders.length === i + 1 ? "last-cell" : ""
                  }`}
                  key={"table-header-" + headerName + i}
                >
                  {headerName}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr
                key={"table-row-" + rowIndex}
                className={`table-row ${
                  tableData.length === rowIndex + 1 ? "last-row" : ""
                }`}
              >
                {Object.keys(row).map((rowKey, rowItem) => (
                  <td
                    className={`table-cell ${
                      Object.keys(row).length === rowItem + 1 ? "last-cell" : ""
                    }`}
                    key={"table-row-item" + rowIndex + rowItem}
                  >
                    {row[rowKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  );
}

export default Table;
