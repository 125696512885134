import React, { useContext } from "react";
import { AppContext } from "../store/appContext";
import Banner from "./banner/banner";
import Section from "./section/section";
import Card from "./card/card";
import Quote from "./quote/quote";
import Table from "./table/table";
import LiveChart from "./liveChart/liveChart";
import WebLoader from "./webLoader/WebLoader";
import { Fade } from "react-awesome-reveal";

function Page({ data, isHomePage }) {
  const {
    state: { mediaBaseUrl, lang },
  } = useContext(AppContext);
  const { contents, banners } = data;
  const isDataReady = Object.keys(data).length > 0;

  const renderPageContent = ({
    content,
    isHomePage = false,
    direction = "",
  }) => {
    switch (content.type) {
      case "section":
      default:
        return (
          <>
            <Section
              title={content[`title_${lang}`]}
              description={content[`description_${lang}`]}
              isLinkable={content.redirect_slug}
              imgUrl={content.image ? mediaBaseUrl + content.image : null}
              isHomePageStyle={isHomePage}
            />
            {content.id === 1 && isHomePage && <LiveChart />}
          </>
        );
      case "card":
        return (
          <Card
            title={content[`title_${lang}`]}
            description={content[`description_${lang}`]}
            isLinkable={content.redirect_slug}
            imgUrl={content.image ? mediaBaseUrl + content.image : null}
            isHomePageStyle={isHomePage}
            direction={direction}
          />
        );
      case "quote":
        return (
          <Quote
            title={content[`title_${lang}`]}
            description={content[`description_${lang}`]}
          />
        );
      case "table":
        return <Table data={content[`additional_data_${lang}`]} />;
    }
  };

  const renderBanner = () => {
    return (
      banners && (
        <Banner
          mediaUrl={mediaBaseUrl + banners[0].path}
          title={banners[0][`title_${lang}`]}
          type={banners[0].type}
        />
      )
    );
  };

  const renderContent = () => {
    return (
      contents && (
        <React.Fragment>
          {Object.keys(contents).map((contentsKey, i) =>
            contents[contentsKey].length > 1 ? (
              <div
                className={`contents-wrapped ${
                  isHomePage ? "home-style" : ""
                } ${contents[contentsKey].length === 3 ? "three" : ""}`}
                key={`contents-${contentsKey}`}
              >
                {contents[contentsKey].map((contentsItem, j) => {
                  let direction = "";
                  if (contents[contentsKey].length === 3) {
                    // fade first left second normal fade and 3rd fade in right
                    if (j % 3 === 0) {
                      direction = "left";
                    } else if (j % 3 === 2) {
                      direction = "right";
                    }
                  } else {
                    direction = j % 2 === 0 ? "left" : "right"; // if even fade in left else from the rights
                  }
                  return (
                    <React.Fragment key={`contents-${contentsKey}${j}`}>
                      {renderPageContent({
                        content: contentsItem,
                        isHomePage,
                        direction,
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            ) : (
              <Fade
                triggerOnce={true}
                delay="25"
                key={`contents-${contentsKey}`}
              >
                {contents[contentsKey].map((contentsItem, j) => (
                  <React.Fragment key={`contentsItem-${contentsKey}-${j}`}>
                    {renderPageContent({ content: contentsItem, isHomePage })}
                  </React.Fragment>
                ))}
              </Fade>
            )
          )}
        </React.Fragment>
      )
    );
  };
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {renderBanner()}
      <div id="page-container">{renderContent()}</div>
    </React.Fragment>
  );
}

export default Page;
