import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/Dispatchhelper";
import Page from "../../components/page";

function Wertsteigerung() {
  const {
    state: { baseUrl, wertsteigerung },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();

  useEffect(() => {
    const slug = "wertsteigerung";
    axios.get(`${baseUrl}contents/${slug}`).then((response) => {
      dispatchAction("setWertsteigerungData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wertsteigerung container">
      <Page data={wertsteigerung} />
    </div>
  );
}

export default Wertsteigerung;
